<template>
    <v-overlay color="white" :opacity="0.70" :value="true">
    </v-overlay>
</template>

<script>

    export default {
        name: "PaymentSuccess",
        beforeCreate() {
            this.$store.commit('session/isPaid', true);
            this.$router.push({name: 'order-complete'});
        },
    }
</script>
